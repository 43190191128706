angular.module('fingerink')
    .directive('avanzadaLabel', function () {
        return {
            restrict: 'AEC',
            replace: true,
            templateUrl: 'directives/widgets/avanzada-label.tpl.html',
            scope: {
                data: '=',
                fillable: '=',
                heightPx: '=',
                widthPx: '='
            },
            controllerAs: 'controller',
            controller: 'avanzadaLabelCtrl',
            link: function ($scope, element, attrs) {
                element.find('input').inputfit({
                    maxSize: element[0].parentElement.getBoundingClientRect().height
                });

                $scope.$on('boundsChanged', ()=>{
                    element.find('input').inputfit({
                        maxSize:element[0].parentElement.getBoundingClientRect().height
                    });
                });

                $scope.$on('resize' + $scope.data.id, function (event, data) {
                    $element.find('input').triggerHandler('keyup.inputfit');
                }); 

                angular.element(window).bind('resize', function() {
                    element.find('input').inputfit({
                        maxSize:element[0].parentElement.getBoundingClientRect().height
                    });
                });

            }
        };
    })
    .controller('avanzadaLabelCtrl', function ($rootScope, $scope, $element, $timeout) {
        var that = this;

        
                   
                 
    });
